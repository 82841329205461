import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { RouteComponentProps, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ReduxSelectors } from "../../redux/shared/types";
import { HTMLVideoStreamElement } from "../WatchParty/types";
import VertoSession from "../../verto/VertoSession";
import { Participant } from "../../verto/models";
import Layout from "../../components/Layout";
import { Routes } from "../../shared/routes";
import FullscreenListeners from "../WatchParty/LivingRoom/FullscreenListeners";
import TopBarStream from "./TopBarStream";
import ProgressLoader from "../../components/ProgressLoader";
import SideBarStream from "./SideBarStream";
import getCamParams from "../../shared/methods/getCamParams";
import NoVideoCanvas from "../../components/NoVideoCanvas";
import Chat from "../../components/Chat";
import {
  EpgEntry,
  SharedStream,
  SharedStreamVlrs,
  Vlr,
} from "../../shared/types";
import SelectRoomModal from "./SelectRoomModal";
import {
  IonAlert, IonIcon,
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
// import ProgressLoaderInvite from '../../components/ProgressLoaderInvite';
import RouterLeaveGuard from "../../components/RouterLeaveGuard";
import EstablishVertoSession from "./EstablishVertoSession";
import PlayerBarStream from "./PlayerBarStream";
import initStream from "./initStream";
import startStreamVlr from "./startStreamVlr";
import exitStreamVlr from "./exitStreamVlr";
import { setErrorToast, setInfoToast } from "../../redux/actions/toastActions";
import { useTranslation } from "react-i18next";
import { UpdateMetadata, VlrService } from "../../services";
import { DOWNLOAD_APP_V_PARAM } from "../../components/DownloadApp";
import { StreamService } from "../../services/StreamService";
import { streamLoadingStart } from "../../redux/actions/streamLoadingActions";
import { API_URL } from "../../shared/constants";
import { VertoLayout } from "../../verto/types";
import StreamDebugInfo from "../../components/StreamDebugInfo";
import RoomConnectionStatus from "../../components/RoomConnectionStatus";
// import AdSenseCard from '../../components/AdSense/AdSenseCard';
// import { AdSenseFormat, AdSenseSlot } from '../../components/AdSense';
// import shopIcon from "../../images/vlr-default-avatar.png"
import appStorage from "../../shared/appStorage";
import GoogleAdStream from "../../components/GoogleAdStream";
import Participants from "../WatchParty/LivingRoom/Participants";
import { motion } from "framer-motion";
import {closeOutline, star} from "ionicons/icons";
import Hls from "hls.js";

export type StreamVlr = {
  moderator: {
    username: string;
    password: string;
  };
  updateMetadata: boolean;
  roomId: string;
  publicId: string;
  fsUrl: string;
  vlrId: number;
  upSpeedUrl: string | null;
  hostName?: string;
  isMyRoom?: boolean;
};

export type ChangeStreamParams = {
  streamName: string;
  streamUrl: string;
  isAdult: boolean;
  epgId?: number;
};

export type UpdateStreamVlr = {
  username: string;
  password: string;
  updateMetadata: boolean;
};

const MUTE_STREAM_LOADING_PREVIEW = "muteStreamLoadingPreview";

const SharedStreamPage: FC<RouteComponentProps> = ({
  location: { search },
}: RouteComponentProps) => {
  const { t } = useTranslation();
  // const history = useHistory();
  const { id, roomId } = useParams<{
    id: string | "camera";
    roomId?: string;
  }>();
  const dispatch = useDispatch();
  const profile = useSelector(({ profile }: ReduxSelectors) => profile);
  const { showDebugInfo } = useSelector(
      ({ profile }: ReduxSelectors) => profile
  );
  // eslint-disable-next-line
  const { astraUrl, previewClip } = useSelector(
    ({ webConfig }: ReduxSelectors) => webConfig
  );
  const { currentStreamRoute } = useSelector(
    ({ stream }: ReduxSelectors) => stream
  );
  // console.log('astraUrl',astraUrl)
  const pageRef = useRef<HTMLDivElement>(null);
  const roomRef = useRef<HTMLVideoStreamElement>(null);
  const vertoSession = useRef<VertoSession | null>(null);
  const timeLoading = useRef<NodeJS.Timeout | null>(null);
  // const streamVideoRef = useRef<HTMLVideoElement>(null);
  const streamVlr = useRef<StreamVlr>({
    roomId: "",
    publicId: "",
    fsUrl: "",
    updateMetadata: true,
    moderator: { username: "", password: "" },
    vlrId: 0,
    upSpeedUrl: "",
    isMyRoom: false,
  });
  const userMediaAudioRef = useRef<MediaStream>();
  const userMediaVideoRef = useRef<MediaStream | null>(null);
  const noVideoTrackRef = useRef<MediaStreamTrack | null>(null);
  const sharedStreamData = useRef<SharedStreamVlrs>();
  const caller = useRef<string>(
    profile.nickname || `User_${new Date().getMilliseconds()}`
  );
  const isStreamingCamera = useRef<boolean>(false);
  const [isRoomPrivate, setIsRoomPrivate] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [showProgressbar, setShowProgressbar] = useState<boolean>(false);
  // eslint-disable-next-line
  const [showInviteProgressbar, setShowInviteProgressbar] =
    useState<boolean>(false);
  const [showLoadingCancel, setShowLoadingCancel] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [imHost, setImHost] = useState<boolean | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [micMuted, setMicMuted] = useState<boolean>(true);
  const [camStopped, setCamStopped] = useState<boolean>(true);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [canLeave, setCanLeave] = useState<boolean>(false);
  const [openSelectRoomModal, setOpenSelectRoomModal] =
    useState<boolean>(false);
  const [vlrs, setVlrs] = useState<Vlr[]>([]);
  const [volume, setVolume] = useState<number>(1);
  const [redirectHome, setRedirectHome] = useState<boolean>(false);
  const [invitationUrl, setInvitationUrl] = useState<string>(
    window.location.href
  );
  const [streamName, setStreamName] = useState<string>("");
  const [streamUrl, setStreamUrl] = useState<string | null>(null);
  const [streamEpg, setStreamEpg] = useState<EpgEntry[]>([]);
  const [streamIsAdult, setStreamIsAdult] = useState<boolean>(false);
  const [showStreamInfo, setShowStreamInfo] = useState<boolean>(showDebugInfo);
  const [isExitAlert, setIsExitAlert] = useState<boolean>(false);
  // eslint-disable-next-line
  const [muteVideo, setMuteVideo] = useState<boolean>(false);
  // const [showAds, setShowAds] = useState<boolean>(false);
  // const [openSelectFsResolution, setOpenSelectFsResolution] = useState<boolean>(false);
  // const [fsResolution, setFsResolution] = useState<number>();

  const [showParticipants, setShowParticipants] = useState<boolean>(false);

  const handleNoVideoTrack = useCallback((track: MediaStreamTrack) => {
    noVideoTrackRef.current = track;
  }, []);

  const startNewRoom = useCallback(() => {
    // setOpenSelectFsResolution(true);
    startStreamVlr({
      timeLoading,
      sharedStreamData,
      streamVlr,
      setShowLoadingCancel,
      setShowInviteProgressbar,
      setProgress,
      setImHost,
    });
  }, []);

  const joinRoom = useCallback((vlr: Vlr) => {
    timeLoading.current = setTimeout(() => setShowLoadingCancel(true), 10000);

    setOpenSelectRoomModal(false);
    setShowProgressbar(true);

    streamVlr.current = {
      roomId: vlr.room_id,
      publicId: vlr.public_id,
      fsUrl: vlr.fs_url || "",
      updateMetadata: false,
      moderator: {
        username: "",
        password: "",
      },
      vlrId: vlr.id,
      upSpeedUrl: vlr.up_speed_url,
      hostName: vlr.host_name,
      isMyRoom: vlr.is_my_room || false,
    };

    setProgress(0.2);
    setImHost(false);
  }, []);

  const handleRoomExit = useCallback(() => {
    exitStreamVlr({
      imHost,
      isStreamingCamera: isStreamingCamera.current,
      vertoSession,
      streamVlr,
      participants,
      userId: profile.id,
    });
  }, [participants, imHost, profile.id]);

  const updateStreamParams = useCallback(
    ({ streamName, streamUrl, isAdult, epgId }: ChangeStreamParams) => {
      if (epgId) {
        StreamService.getEpgEntries(epgId).then(({ data }) =>
          setStreamEpg(data)
        );
      } else {
        setStreamEpg([]);
      }
      setStreamName(streamName);
      setStreamUrl(streamUrl);
      setStreamIsAdult(isAdult);
    },
    []
  );

  useIonViewWillEnter(() => {
    if (id === "camera" && !roomId) {
      dispatch(setErrorToast("sharedStream.noStreamRoomId"));
      setCanLeave(true);
      return;
    }

    initStream({
      id,
      roomId,
      jwt: profile.jwt,
      setIsFullscreen,
      setLoading,
      setShowProgressbar,
      setOpenSelectRoomModal,
      setProgress,
      setVlrs,
      sharedStreamData,
      onStartNewRoom: startNewRoom,
      onJoinRoom: joinRoom,
      onExitRoom: (errorMessage?: string) => {
        if (errorMessage) {
          dispatch(setErrorToast(errorMessage));
        } else {
          dispatch(
            setInfoToast(
              isPlatform("ios")
                ? "notifications.iosNoStreamSupport"
                : "notifications.roomNotActiveLogin"
            )
          );
        }

        setCanLeave(true);
      },
    });
  }, []);

  // console.log("user media audio settings", userMediaAudioRef.current?.getAudioTracks()[0].getSettings())
  // console.log("user media audio constraint", userMediaAudioRef.current?.getAudioTracks()[0].getConstraints())

  useIonViewWillLeave(() => {
    userMediaAudioRef.current
      ?.getAudioTracks()
      .forEach((track) => track.stop());
    userMediaVideoRef.current
      ?.getVideoTracks()
      .forEach((track) => track.stop());
    timeLoading.current && clearTimeout(timeLoading.current);
    vertoSession.current?.notification.removeAllSubscribers();
  }, []);

  useEffect(() => {
    const url = roomId
      ? window.location.href
      : `${window.location.href}/${streamVlr.current.publicId}`;
    setInvitationUrl(url);
  }, [roomId, streamVlr.current.publicId]);

  useEffect(() => {
    const version = new URLSearchParams(search).get("v");
    setRedirectHome(version === DOWNLOAD_APP_V_PARAM);
  }, [search]);

  useEffect(() => {
    const onBeforeUnloadListener = (e: any) => {
      // e.preventDefault()
      // e.returnValue = "Are you sure you want to leave this page?";
      handleRoomExit();
      // setCanLeave(true);
    };

    window.addEventListener("beforeunload", onBeforeUnloadListener);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnloadListener);
    };
  }, [handleRoomExit]);

  useEffect(() => {
    if (sharedStreamData.current) {
      setStreamName(sharedStreamData.current.name);
      setStreamUrl(sharedStreamData.current.url);
      setStreamIsAdult(sharedStreamData.current?.is_adult_content || false);
      setIsRoomPrivate(sharedStreamData.current?.is_adult_content || false);
      setStreamEpg(sharedStreamData.current?.epg_channel?.entries || []);
    }

    // console.log("imHost", imHost, "streamVlr", streamVlr.current, "sharedshared", sharedStreamData.current)

    if (
      imHost &&
      streamVlr.current.updateMetadata &&
      sharedStreamData.current
    ) {
      const updateMetaData: UpdateMetadata = {
        roomId: streamVlr.current.publicId,
        streamCamera: false,
        streamId: sharedStreamData.current.id,
        streamUrl: sharedStreamData.current.url,
        isPrivate: sharedStreamData.current.is_adult_content || false,
        channelLogo: sharedStreamData.current.logo_image?.url
          ? `${API_URL}${sharedStreamData.current.logo_image.url}`
          : sharedStreamData.current.logo,
        channelName: `${sharedStreamData.current.name} ${t(
          "sharedStream.by"
        )} ${caller.current}`,
        channelGenre: sharedStreamData.current.genre,
        channelDescription: "",
        channelLanguage: sharedStreamData.current.language,
        isHost: true,
        userId: profile.id,
      };

      VlrService.updateMetadata(updateMetaData).then();
    }
  }, [imHost, t, profile.id]);

  const handleToggleMic = () => {
    setMicMuted((prevState) => !prevState);
    vertoSession.current?.togglePrimaryMic();
  };

  const handleToggleCam = (cam: string) => {
    if (cam === "none") {
      throw new Error("Camera is not selected");
    }

    const toggleCam = async () => {
      let mediaStream: MediaStream | null = null;

      setCamStopped((prevState) => !prevState);

      if (camStopped) {
        mediaStream = userMediaVideoRef.current =
          await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: getCamParams(cam),
          });
      } else {
        if (noVideoTrackRef.current) {
          mediaStream = new MediaStream([noVideoTrackRef.current]);
        }
      }

      if (mediaStream && vertoSession.current) {
        debugger;
        vertoSession.current.replacePrimaryTracks(mediaStream);
        vertoSession.current.togglePrimaryCam();

        if (!camStopped) {
          userMediaVideoRef.current
            ?.getVideoTracks()
            .forEach((track) => track.stop());
        }
      }
    };

    toggleCam().catch((err) => console.error(err));
  };

  const handleFullscreenChange = () => {
    setIsFullscreen((prevState) => {
      if (prevState) {
        document.exitFullscreen().then();
      } else {
        pageRef.current?.requestFullscreen().then();
      }
      return !prevState;
    });
  };

  const [theatreMode, setTheatreMode] =
      useState<boolean>(false);

  const handleTheatreModeChange = () => {
    setTheatreMode(prevState => {
      return !prevState;
    });
  };

  const handleOnStartNewRoomModal = () => {
    setOpenSelectRoomModal(false);
    startNewRoom();
  };

  const handleOnCancelModal = () => {
    setOpenSelectRoomModal(false);
    setCanLeave(true);
  };

  const handlePlayerVolumeChange = (value: number) => {
    setVolume(value);
    if (!imHost && roomRef.current) {
      roomRef.current.volume = value;
    }
  };

  const handleDismissLoading = useCallback(() => {
    // setProgress(0.9);

    if (roomRef.current) {
      roomRef.current.muted = false;
      setLoading(false);
      setShowProgressbar(false);
      setShowInviteProgressbar(false);
      // setProgress(1);
    }

    timeLoading.current && clearTimeout(timeLoading.current);
  }, []);

  const handleProgressChange = useCallback((value: number) => {
    console.log("progress value", value);
    setProgress(value);
  }, []);

  const handleParticipantsChange = useCallback(
    (participants: Participant[]) => {
      setParticipants(participants);
    },
    []
  );

  const handleCanLeaveChange = useCallback((value: boolean) => {
    setCanLeave(value);
  }, []);

  const handleUpdateStreamVlr = useCallback(
    ({ username, password, updateMetadata }: UpdateStreamVlr) => {
      console.log("streamVlr", streamVlr.current);

      streamVlr.current.moderator.username = username;
      streamVlr.current.moderator.password = password;
      streamVlr.current.updateMetadata = updateMetadata;
    },
    []
  );

  const handleUserMediaChange = useCallback((stream: MediaStream) => {
    userMediaAudioRef.current = stream;
    userMediaVideoRef.current = stream;
  }, []);

  const handleImHostChange = useCallback((value: boolean) => {
    setImHost(value);
  }, []);

  const handleStreamCameraChange = useCallback(() => {
    isStreamingCamera.current = true;
  }, []);

  const handleVertoSessionChange = useCallback(
    (session: VertoSession) => {
      vertoSession.current = session;

      session.notification.onChatMessageStreamChange.subscribe((params) => {
        if (sharedStreamData.current) {
          sharedStreamData.current.url = params.streamUrl;
          sharedStreamData.current.name = params.streamName;
          sharedStreamData.current.is_adult_content = params.isAdult;
        }
        updateStreamParams(params);
      });

      session.notification.onConnectedToRoom.subscribe(() => {
        setMicMuted(true);
        setCamStopped(true);
      });
    },
    [updateStreamParams]
  );

  const handleStreamIsPlaying = useCallback(() => {
    if (sharedStreamData.current) {
      StreamService.updatePlayedSuccessfully(
        sharedStreamData.current.id,
        true
      ).then();
    }
  }, []);

  const handleRemoveStream = useCallback(() => {
    if (sharedStreamData.current) {
      sharedStreamData.current.id = 0;
    }
    setStreamName("");
    setStreamUrl(null);
  }, []);

  const handleStreamPlayFail = () => {
    // if (sharedStreamData.current) {
    // StreamService.updatePlayedSuccessfully(sharedStreamData.current.id, false).then();
    // }
  };

  // const handlePlayerStreamChange = useCallback((stream: MediaStream) => {
  //   setPlayerStream(stream);
  // }, []);

  const handleStreamChange = ({
    id,
    name,
    url,
    logo,
    is_adult_content,
    epg_channel,
    genre,
    language,
  }: SharedStream) => {
    if (streamName === name && streamUrl === url) {
      return;
    }

    if (sharedStreamData.current) {
      sharedStreamData.current.id = id;
    }

    const regex = new RegExp(astraUrl);

    if (!astraUrl || regex.test(url)) {
      StreamService.requestAstraStreamOpening(url).then();
    }

    console.log("stream audio", url);

    // VlrService.patchMetadata({
    //   channelName: `${name} ${t('sharedStream.by')} ${caller.current}`,
    //   streamId: id,
    //   publicId: streamVlr.current.publicId,
    //   logo,
    //   isPrivate: is_adult_content ? true : isRoomPrivate
    // }).then();

    console.log(
      "to update meta data on secondary stream again",
      sharedStreamData
    );

    if (sharedStreamData.current) {
      const updateMetaData: UpdateMetadata = {
        roomId: streamVlr.current.publicId,
        streamCamera: false,
        streamId: id,
        streamUrl: url,
        isPrivate: is_adult_content || false,
        channelLogo: logo,
        channelName: `${name} ${t("sharedStream.by")} ${caller.current}`,
        channelGenre: genre,
        channelDescription: "",
        channelLanguage: language,
        isHost: true,
        userId: profile.id,
      };

      VlrService.updateMetadata(updateMetaData).then();
    }

    const params: ChangeStreamParams = {
      streamName: name,
      streamUrl: url,
      isAdult: is_adult_content || false,
      epgId: epg_channel?.id,
    };

    vertoSession.current?.sendMessage.streamChange(params);
    updateStreamParams(params);
    dispatch(streamLoadingStart());
  };

  const handleCanLeave = () => {
    handleRoomExit();
  };

  const handleChangeRoomLayout = (layout: VertoLayout) => {
    vertoSession.current?.changeLayout(layout);
  };

  const handleExitAlert = () => {
    setIsExitAlert(true);
  };

  // const handleStartNewRoom = (fsResolution: number) => {
  //   setFsResolution(fsResolution);
  //   // setOpenSelectFsResolution(false);
  //
  //   startStreamVlr({
  //     dispatch,
  //     timeLoading,
  //     sharedStreamData,
  //     streamVlr,
  //     setShowLoadingCancel,
  //     setShowInviteProgressbar,
  //     setCanLeave,
  //     setProgress,
  //     setImHost
  //   });
  // }

  // useEffect(() => {
  //   const unlisten = history.listen((params) => {
  //     if(params.pathname !== "") {

  //     }
  //   })
  // }, [history])

  // setLoading(false);
  // setShowProgressbar(false);
  // setShowInviteProgressbar(false);

  const hanldeRedirectRoute = () => {
    switch (currentStreamRoute) {
      case "FROM_CHANNEL":
        return Routes.Channels;
      case "FROM_GENRE":
        return Routes.Genre;
      case "FROM_HOME":
        return Routes.Home;
      default:
        return Routes.Home;
    }
  };

  console.log("ads1 roomRef.current?.srcObject", roomRef.current?.srcObject);
  console.log("ads1 userMediaVideoRef", userMediaVideoRef.current?.getTracks());
  console.log("ads2", vertoSession.current?.hasSecondaryCall());

  // useEffect(() => {
  //   if(!vertoSession?.current?.hasSecondaryCall()) {
  //     setShowAds(true)
  //   } else {
  //     setShowAds(false)
  //   }
  // }, [])

  // useEffect(() => {
  //   if (streamVideoRef.current) {
  //     streamVideoRef.current.play().then();
  //     if (showAds) {
  //     } else {
  //       streamVideoRef.current.pause();
  //     }
  //   }
  // }, [streamVideoRef, showAds]);

  useEffect(() => {
    const value = appStorage.getItem(MUTE_STREAM_LOADING_PREVIEW);
    if (value && value === "true") {
      setMuteVideo(true);
    }
  }, []);

  console.log("progress", progress);


  const [adPlaying, setAdPlaying] = useState(false);
  const adTimerRef = useRef<NodeJS.Timeout | null>(null);
  const adStartTimeRef = useRef<number | null>(null);
  const url="https://streams2.sofast.tv/ptnr-mgc/title-4K-TRAVEL-TV-ENG/sofastplayout/33c31ac4-51fa-46ae-afd0-0d1fe5e60a80_0_HLS/manifest.m3u8"

  interface HlsSegment {
    cueOut?: boolean;
    daterange?: any;
    tagList?: string[][];
  }

  useEffect(() => {
    if (Hls.isSupported() && roomRef.current) {
      const hls = new Hls({
        debug: true,
        enableCEA708Captions: true,
        fragLoadingMaxRetry: 5,
        manifestLoadingMaxRetry: 5,
        levelLoadingMaxRetry: 5,
        xhrSetup: function(xhr) {
          // Add CORS headers if needed
          xhr.withCredentials = false;
        }
      });

      // Add more detailed logging for debugging
      console.log('===================> Attempting to load HLS stream:', url);
      
      hls.loadSource(url);
      hls.attachMedia(roomRef.current);

      // Listen for manifest parsing
      hls.on(Hls.Events.MANIFEST_PARSED, (_, data) => {
        console.log('===================> HLS Manifest Loaded successfully:', data);
        console.log('===================> Available quality levels:', data.levels.map(level => 
          `${level.width}x${level.height} @${level.bitrate/1000}kbps`
        ));
        
        // Start with lowest quality to ensure playback
        hls.loadLevel = 0;
        roomRef.current?.play().catch(err => {
          console.error('===================> Playback error:', err);
          // Try to recover from autoplay restrictions
          if (err.name === 'NotAllowedError') {
            console.log('===================> Autoplay prevented, muting and trying again');
            if (roomRef.current) {
              roomRef.current.muted = true;
              roomRef.current.play().catch(e => console.error('===================> Still cannot play:', e));
            }
          }
        });
      });

      // Listen for all tags in the playlist
      hls.on(Hls.Events.LEVEL_LOADED, (_, data) => {
        console.log('===================> Level loaded with details:', data.details);
        
        // Check for SCTE-35 markers in the playlist
        if (data.details.fragments) {
          data.details.fragments.forEach((fragment, index) => {
            if (fragment.tagList) {
              fragment.tagList.forEach(tag => {
                if (tag[0] === 'EXT-X-CUE-OUT' || tag[0] === 'EXT-X-DATERANGE') {
                  console.log(`===================> Ad marker found in fragment ${index}:`, tag);
                }
              });
            }
          });
        }
      });

      // More precise detection of ad segments
      hls.on(Hls.Events.FRAG_PARSING_METADATA, (_, data) => {
        if (data.samples && data.samples.length) {
          console.log('===================> Fragment metadata detected:', data);
          // Check for ID3 tags that might indicate ads
          data.samples.forEach(sample => {
            if (sample.data) {
              console.log('===================> Sample data:', sample);
            }
          });
        }
      });

      hls.on(Hls.Events.FRAG_CHANGED, (_, data) => {
        const fragment = data.frag;
        console.log('===================> Fragment changed:', fragment);
        
        if (fragment.tagList) {
          const hasCueOut = fragment.tagList.some(tag => 
            tag[0] === 'EXT-X-CUE-OUT' || 
            (tag[0] === 'EXTINF' && tag[1] && tag[1].includes('CUE'))
          );
          
          const hasCueIn = fragment.tagList.some(tag => 
            tag[0] === 'EXT-X-CUE-IN'
          );
          
          const hasDateRange = fragment.tagList.some(tag => 
            tag[0] === 'EXT-X-DATERANGE' && 
            tag[1] && 
            (tag[1].includes('SCTE35') || tag[1].includes('AD'))
          );
      
          if ((hasCueOut || hasDateRange) && !adPlaying) {
            console.log('===================> Ad break started', fragment);
            setAdPlaying(true);
            
            // Start ad timer
            adStartTimeRef.current = Date.now();
            adTimerRef.current = setInterval(() => {
              if (adStartTimeRef.current) {
                const elapsedSeconds = Math.floor((Date.now() - adStartTimeRef.current) / 1000);
                console.log(`===================> Ad break duration: ${elapsedSeconds} seconds`);
              }
            }, 1000);
            
          } else if (hasCueIn && adPlaying) {
            console.log('===================> Ad break ended', fragment);
            setAdPlaying(false);
            
            // Stop ad timer and log total duration
            if (adTimerRef.current) {
              clearInterval(adTimerRef.current);
              adTimerRef.current = null;
            }
            
            if (adStartTimeRef.current) {
              const totalDuration = Math.floor((Date.now() - adStartTimeRef.current) / 1000);
              console.log(`===================> Total ad break duration: ${totalDuration} seconds`);
              adStartTimeRef.current = null;
            }
          }
        }
      });
      

      // Listen for parsing errors that might indicate ad-related issues
      hls.on(Hls.Events.ERROR, (event, data) => {
        console.log('===================> HLS Error:', data);
        
        if (data.fatal) {
          switch(data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.log('===================> Fatal network error encountered, trying to recover');
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log('===================> Fatal media error encountered, trying to recover');
              hls.recoverMediaError();
              break;
            default:
              // Cannot recover from other fatal errors
              console.log('===================> Fatal error, cannot recover:', data);
              hls.destroy();
              break;
          }
        } else {
          // Non-fatal error, logging details for debugging
          console.log('===================> Non-fatal error:', {
            type: data.type,
            details: data.details,
            fatal: data.fatal,
            response: data.response,
            url: data.context?.url
          });
          
          // For bufferAppendError specifically
          if (data.details === 'bufferAppendError') {
            console.log('===================> Buffer append error, trying to recover');
            hls.recoverMediaError();
          }
        }
      });

      return () => {
        console.log('===================> Cleaning up HLS instance');
        if (adTimerRef.current) {
          clearInterval(adTimerRef.current);
        }
        hls.destroy();
      };

    }
  }, [url]);

  return (
    <Layout>
      {/*<FsRoomResolution*/}
      {/*  show={openSelectFsResolution}*/}
      {/*  onSelect={handleStartNewRoom}*/}
      {/*/>*/}

      {imHost !== null && (
        <EstablishVertoSession
          isRoomPrivate={isRoomPrivate}
          volume={volume}
          micMuted={micMuted}
          imHost={imHost}
          caller={caller.current}
          roomRef={roomRef}
          timeLoading={timeLoading.current}
          streamVlr={streamVlr.current}
          streamName={streamName}
          streamUrl={streamUrl}
          noVideoTrack={noVideoTrackRef.current}
          onUserMedia={handleUserMediaChange}
          onVertoSession={handleVertoSessionChange}
          onDismissLoading={handleDismissLoading}
          setShowProgressbar={setShowProgressbar}
          setLoading2={setLoading}
          setShowInviteProgressbar={setShowInviteProgressbar}
          onProgress={handleProgressChange}
          onParticipants={handleParticipantsChange}
          onCanLeave={handleCanLeaveChange}
          onStreamCamera={handleStreamCameraChange}
          onUpdateStreamVlr={handleUpdateStreamVlr}
          onImHost={handleImHostChange}
          onStreamIsPlaying={handleStreamIsPlaying}
          onStreamPlayFail={handleStreamPlayFail}
          onRemoveStream={handleRemoveStream}
          // fsResolution={fsResolution}
        />
      )}
      <main
          ref={pageRef}
          className={`shared-stream-page ${isFullscreen ? "fullscreen" : ""}`}
      >
        {/* <ProgressLoader
          progress={progress}
          show={showProgressbar}
          showLeave={showLoadingCancel}
          onLeave={handleCanLeave}
        /> */}

        {/* <ProgressLoaderInvite
          progress={progress}
          show={showInviteProgressbar}
          showLeave={showLoadingCancel}
          invitationUrl={invitationUrl}
          onLeave={handleCanLeave}
        /> */}

        {
            !theatreMode && (
                <section className="shared-stream-chat-section">
                  {vertoSession.current && (
                      <Chat
                          vlrId={streamVlr.current.vlrId}
                          session={vertoSession.current}
                          participants={participants}
                          show={showChat}
                      />
                  )}
                </section>
            )
        }

        <motion.div
            initial={{x: "-100%"}}
            animate={{x: theatreMode && showChat ? "0%" : "-100%"}}
            transition={{type: "spring", stiffness: 300, damping: 30}}
            className="fixed top-0 left-0 w-96 h-full bg-black shadow-lg z-50 flex"
        >
          <section className="shared-stream-chat-section theatre h-100">
            {vertoSession.current && (
                <Chat
                    vlrId={streamVlr.current.vlrId}
                    session={vertoSession.current}
                    participants={participants}
                    show={showChat}
                />
            )}
          </section>
        </motion.div>

        <section className={!theatreMode ? "shared-stream-room-section" : "shared-stream-room-section theatre-mode"}>
          {progress < 0.9 && <GoogleAdStream className="ads-image"/>}
          <div
              className="stream-content-holder"
              style={{
                visibility:
                    !!roomRef.current?.srcObject && !loading ? "visible" : "hidden",
              }}
          >
            <TopBarStream
                streamName={streamName}
                roomId={streamVlr.current?.publicId}
                epgEntries={streamEpg}
                participants={participants}
                onExit={handleExitAlert}
            />
            <div
                className={`stream-holder ${
                    roomRef.current?.srcObject === null ? "left" : ""
                }`}
            >
              <div className="stream-room-container">
                {
                    !vertoSession?.current?.hasSecondaryCall() &&
                    imHost &&
                    progress === 1 && (
                        // <video ref={streamVideoRef} className="ads-image" src={`${API_URL}${previewClip}`} muted={true} loop playsInline autoPlay={true} />
                        // <GoogleAdStream className="ads-image" />
                        <></>
                    )

                  // <IonImg src={shopIcon} className='ads-image' />

                  // <AdSenseCard
                  //   slot={AdSenseSlot.Down}
                  //   format={AdSenseFormat.LargeSquare}
                  //   className="ad g-ad-container"
                  // />
                }

                <video
                    ref={roomRef}
                    muted
                    autoPlay
                    className="stream-room-video"
                    playsInline
                />
                {adPlaying && <div className="ad-notification">Ad Break Playing...</div>}

                {vertoSession.current && (
                    <RoomConnectionStatus vertoSession={vertoSession.current}/>
                )}

              </div>

              <NoVideoCanvas onVideoTrack={handleNoVideoTrack}/>

              <SideBarStream
                  streamId={sharedStreamData.current?.id}
                  showStreamInfo={showStreamInfo}
                  isAdult={streamIsAdult}
                  isPrivate={isRoomPrivate}
                  publicId={streamVlr.current.publicId}
                  imHost={imHost}
                  show={!!roomRef.current?.srcObject && !loading}
                  micMuted={micMuted}
                  camStopped={camStopped}
                  fullscreen={isFullscreen}
                  showChat={showChat}
                  invitationUrl={invitationUrl}
                  onToggleMic={handleToggleMic}
                  onToggleCam={handleToggleCam}
                  onFullscreen={handleFullscreenChange}
                  onTheatreMode={handleTheatreModeChange}
                  onShowChat={setShowChat}
                  onLayoutChange={handleChangeRoomLayout}
                  onChangeStream={handleStreamChange}
                  onChangeRoomStatus={setIsRoomPrivate}
                  onShowStreamInfo={setShowStreamInfo}
                  showParticipants={showParticipants}
                  theatreMode={theatreMode}
                  onShowParticipants={setShowParticipants}
              />

              <PlayerBarStream
                  volume={volume}
                  onVolumeChange={handlePlayerVolumeChange}
              />
            </div>
          </div>

          <FullscreenListeners isInFullscreen={isFullscreen}/>
        </section>

        {
            !theatreMode && (
                <section className="shared-stream-side-features">
                  {showStreamInfo && <StreamDebugInfo/>}
                  {
                      vertoSession.current &&
                      <Participants
                          session={vertoSession.current}
                          participants={participants}
                          host
                          show={showParticipants}
                      />
                  }
                </section>
            )
        }

        <motion.div
            initial={{x: "-100%"}}
            animate={{x: (theatreMode && showStreamInfo) || (theatreMode && showParticipants) ? "0%" : "-100%"}}
            transition={{type: "spring", stiffness: 300, damping: 30}}
            className="fixed top-0 left-0 w-96 h-full bg-black shadow-lg z-50"
        >
          <section className="shared-stream-side-features">
            {showStreamInfo && <StreamDebugInfo/>}
            {
                vertoSession.current &&
                <Participants
                    session={vertoSession.current}
                    participants={participants}
                    host
                    show={showParticipants}
                />
            }
          </section>
        </motion.div>

      </main>

      <SelectRoomModal
          open={openSelectRoomModal}
          vlrs={vlrs}
          onStartNewRoom={handleOnStartNewRoomModal}
          onJoinRoom={joinRoom}
          onCancel={handleOnCancelModal}
      />

      <RouterLeaveGuard
          canLeave={canLeave}
          defaultDestination={hanldeRedirectRoute()}
          redirectTo={redirectHome ? hanldeRedirectRoute() : null}
          onCanLeave={handleCanLeave}
      />

      {isExitAlert && (
          <IonAlert
              isOpen={isExitAlert}
              onDidDismiss={() => setIsExitAlert(false)}
              message={t("watchPartyStart.aboutToLeave")}
              buttons={[
                {
                  text: `${t("common.decline")}`,
                  role: "cancel",
                },
                {
                  text: `${t("common.leave")}`,
                  handler: () => {
                    handleCanLeave();
                  },
                },
              ]}
        />
      )}
    </Layout>
  );
};

export default SharedStreamPage;
