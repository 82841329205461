import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import {
  IonAvatar, IonCard, IonCardContent, IonCardHeader,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonPopover,
  IonSelect,
  IonSelectOption, IonText,
  IonToolbar,
  useIonRouter,
  // useIonRouter
} from "@ionic/react";
import { globeOutline, personCircleOutline } from "ionicons/icons";
import headerLogo from "../../images/12all-hub-logo.svg";
import { ILanguage, LANGUAGES } from "../../shared/Language";
import { useHistory, useLocation } from "react-router";
import { Routes } from "../../shared/routes";
import { useDispatch, useSelector } from "react-redux";
import { ReduxSelectors } from "../../redux/shared/types";
import { setLogout } from "../../redux/actions/profileActions";
import appStorage, { StorageKey } from "../../shared/appStorage";
import { setInfoToast } from "../../redux/actions/toastActions";
import setLanguage from "../../redux/actions/languageActions";
import i18n from "../../i18n";
import { MAIN_CONTENT_ID } from "../../shared/constants";
import HeaderSearchbar from "../HeaderSearchbar";
import redSharpStar from "../../images/icons/star-sharp.svg";
import { setTotalStarBalance } from "../../redux/actions/billingRewardActions";

type MenuItemsProps = {
  inToolbar?: boolean;
};

const MenuItems: FC<MenuItemsProps> = ({
                                         inToolbar,
                                       }: MenuItemsProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const router = useIonRouter();
  const history = useHistory();
  const profile = useSelector(({ profile }: ReduxSelectors) => profile);
  const language = useSelector(({ language }: ReduxSelectors) => language);
  const { starsBalance } = useSelector(
      ({ billingRewards }: ReduxSelectors) => billingRewards
  );
  const lines = useRef<"none" | "full">(inToolbar ? "none" : "full");
  const [activeRoute, setActiveRoute] = useState<string>("");
  const [selectedStar, setSelectedStar] = useState<string>("");

  useEffect(() => {
    setActiveRoute(pathname);
  }, [pathname]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (selectedStar) {
        setSelectedStar("");
      }
    });

    return () => {
      unlisten();
    };
  }, [history, selectedStar]);

  const dispatch = useDispatch();

  return (
      <>
        {/* billing: */}
        <IonItem
            lines={lines.current}
            data-id="stars"
            className="star-balance-display stars-item"
        >
          <IonImg src={redSharpStar} />
          <IonLabel>{`${ profile.jwt &&
          !profile.isAnonymous &&
          ((!!profile?.email && !profile?.email.includes("@skiplogin.com")) || profile?.email === null) ? starsBalance : "Stars"}`}</IonLabel>
        </IonItem>
      </>
  );
};

const Header: FC = () => {

  const [timeLeft, setTimeLeft] = useState(300);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
      <>
        <IonHeader className="app-header-2">
          <IonToolbar className="header-toolbar">
            <IonItemGroup className="nav-container flex !justify-between">

              <IonItemGroup className="flex justify-start">
                <IonItem
                    data-id="reward"
                    className="star-balance-display stars-item"
                >
                  <IonImg src={redSharpStar} />
                  <IonLabel>Next reward in <b className={"ms-5"} style={{ color: "#ffab00" }}>{formatTime(timeLeft)}</b></IonLabel>
                </IonItem>
              </IonItemGroup>

              <IonItemGroup className="flex justify-end">
                <MenuItems
                    inToolbar
                />
              </IonItemGroup>
            </IonItemGroup>
          </IonToolbar>
        </IonHeader>
      </>
  );
};

export default Header;
