// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../node_modules/video.js/dist/video-js.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../node_modules/videojs-ima/dist/videojs.ima.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Ensures the ad container is above the video player */\n#ad-container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 210; /* Higher z-index for ad container */\n}\n\n/* Video player behind ad container */\n#video-player {\n  width: 100%;\n  height: 100%;\n  z-index: 200;\n}\n\n/* Mute button with appropriate positioning and z-index */\n#mute-btn {\n  position: absolute; /* Use absolute to allow custom positioning */\n  bottom: 20px;\n  right: 20px;\n  z-index: 301; /* Ensure the button is on top of everything */\n}", "",{"version":3,"sources":["webpack://./src/components/GoogleAdStream/styles.scss"],"names":[],"mappings":"AAGA,uDAAA;AACA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA,EAAA,oCAAA;AAAF;;AAGA,qCAAA;AACA;EACE,WAAA;EACA,YAAA;EACA,YAAA;AAAF;;AAGA,yDAAA;AACA;EACE,kBAAA,EAAA,6CAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA,EAAA,8CAAA;AAAF","sourcesContent":["@import \"video.js/dist/video-js.css\";\n@import \"videojs-ima/dist/videojs.ima.css\";\n\n/* Ensures the ad container is above the video player */\n#ad-container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 210; /* Higher z-index for ad container */\n}\n\n/* Video player behind ad container */\n#video-player {\n  width: 100%;\n  height: 100%;\n  z-index: 200;\n}\n\n/* Mute button with appropriate positioning and z-index */\n#mute-btn {\n  position: absolute; /* Use absolute to allow custom positioning */\n  bottom: 20px;\n  right: 20px;\n  z-index: 301; /* Ensure the button is on top of everything */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
