import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonImg,
  IonItem,
  IonList,
  IonListHeader,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import logo from "../../images/12all-logo-128.png";
import sharpStar from "../../images/icons/star-sharp.svg";
import { Vlr, VlrParticipant } from "../../shared/types";
import { Routes } from "../../shared/routes";
import RoomActions from "../RoomActions";
import { API_URL } from "../../shared/constants";
import { BillingServices } from "../../services";
import { setErrorToast, setInfoToast, setWarnToast } from "../../redux/actions/toastActions";
import { updateStarsBalance } from "../../shared/helpers";
import { setTotalStarBalance } from "../../redux/actions/billingRewardActions";

type Props = {
  room: Vlr;
};

interface Participant extends VlrParticipant {
  firstLetter: string;
}

const parseStatAt = (startAt: string) => {
  const startAtDate = new Date(startAt);
  return `${startAtDate.getHours()}:${startAtDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
};

const LiveRoom: React.FC<Props> = ({ room }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state.profile);
  const billingRewards = useSelector((state: any) => state.billingRewards);
  const [previewLoaded, setPreviewLoaded] = useState<{
    [key: number]: boolean;
  }>({});
  const startedAt = useRef<string>(
    room.started_at ? parseStatAt(room.started_at) : ""
  );
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [hostFirstLetter, setHostFirstLetter] = useState<string>("");
  const [host, setHost] = useState<VlrParticipant>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [roomPrice, setRoomPrice] = useState(0);

  useEffect(() => {
    const participants = room.participants
      .filter((p) => p.role !== "host")
      .slice(0, 5)
      .reverse()
      .map((participant) => ({
        ...participant,
        firstLetter: participant.nickname
          ? participant.nickname.charAt(0)
          : "U",
      }));

    setParticipants(participants);

    const host = room.participants.find(({ role }) => role === "host");
    setHost(host);
    host?.nickname && setHostFirstLetter(host.nickname.charAt(0));
  }, [room.participants]);

  const onItemClick = async () => {
    BillingServices.getRoomPrice(room.channel.channel_deep_link).then(
      ({ data: { result: roomPriceResult } }) => {
        setRoomPrice(roomPriceResult === "" ? 0 : roomPriceResult.stars);
        if (
          (roomPriceResult === "" || roomPriceResult.stars === 0) &&
          profile.id === 0
        ) {
          history.push(
            `${Routes.WatchParty}/${room.channel.channel_deep_link}/m`
          );
        } else if (
          (roomPriceResult === "" || roomPriceResult.stars === 0) &&
          profile.id !== 0
        ) {
          history.push(
            `${Routes.WatchParty}/${room.channel.channel_deep_link}`
          );
        } else if (
          roomPriceResult &&
          roomPriceResult.stars > 0 &&
          profile.id === 0
        ) {
          dispatch(setInfoToast("login.loginFirst"));
        } else if (
          roomPriceResult &&
          roomPriceResult.stars > 0 &&
          profile.id !== 0
        ) {
          BillingServices.isRoomPaid(
            profile.id,
            room.channel.channel_deep_link
          ).then(({ data: { result: isRoomPaidResult } }) => {
            console.log("PAID:", isRoomPaidResult.paid, isRoomPaidResult);
            if (isRoomPaidResult.paid) {
              history.push(
                `${Routes.WatchParty}/${room.channel.channel_deep_link}/m`
              );
            } else {
              if (billingRewards.starsBalance < roomPriceResult.stars) {
                dispatch(
                  setWarnToast(t("billing.enteringRoom.notEnoughStars"))
                );
              } else {
                setIsOpen(true);
              }
            }
          });
        }
      }
    );
  };

  const onYesClick = () => {
    BillingServices.payRoomPrice(
      profile.id,
      room.channel.channel_deep_link
    ).then(async ({ data }) => {
      if (data.status === "ok" && data.result.status === "SUCCESS") {
        const starsBalance = await updateStarsBalance(profile.id);
        dispatch(setTotalStarBalance(starsBalance));
        dispatch(setInfoToast(t("billing.enteringRoom.paidSuccess")));
        history.push(`${Routes.WatchParty}/${room.channel.channel_deep_link}/m`);
        setIsOpen(false);
      } else if (data.status === "ok" && data.result.status !== "SUCCESS") {
        dispatch(setInfoToast(t("billing.enteringRoom.notEnoughStars")));
      } else {
        dispatch(setErrorToast(t("myProfile.notifications.errorSave")));
        setIsOpen(false);
      }
    });
  };

  const onNoClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IonList className="live-room">
        {startedAt.current && (
          <IonListHeader>
            {t("liveRoom.startedAt")} {startedAt.current}
          </IonListHeader>
        )}
        <IonItem onClick={onItemClick} className="room-meta" lines="none">
          <IonImg
            src={
              !room.channel.is_adult_content && room.channel.logo
                ? room.channel.logo
                : logo
            }
            className={
              previewLoaded[room.id]
                ? "room-logo-small"
                : `room-logo${room.channel.logo ? " has-logo" : ""}`
            }
          />

          {room.channel.https_preview_high && (
            <IonImg
              src={room.channel.https_preview_high}
              className={`room-preview${
                previewLoaded[room.id] ? " room-loaded" : ""
              }`}
              onIonImgDidLoad={() =>
                setPreviewLoaded((prev) => ({ ...prev, [room.id]: true }))
              }
            />
          )}

          <IonText color="dark">{room.channel.name}</IonText>
        </IonItem>

        <IonItem lines="none" className="participants">
          {host?.avatar ? (
            <IonAvatar
              slot="start"
              className="host"
              title={host.nickname || ""}
            >
              <img src={`${API_URL}${host.avatar}`} alt="" />
            </IonAvatar>
          ) : hostFirstLetter ? (
            <IonText
              slot="start"
              className="host-default-avatar"
              title={host?.nickname || ""}
            >
              {hostFirstLetter}
            </IonText>
          ) : null}

          <IonList className="participants-list">
            {participants.map(
              ({ id, nickname, avatar, firstLetter, color }) => (
                <div key={id} title={nickname} className="participant">
                  {avatar ? (
                    <IonAvatar>
                      <img src={`${API_URL}${avatar}`} alt="" />
                    </IonAvatar>
                  ) : firstLetter ? (
                    <IonText
                      className="default-avatar"
                      style={{ backgroundColor: color }}
                    >
                      {firstLetter}
                    </IonText>
                  ) : null}
                </div>
              )
            )}
          </IonList>
        </IonItem>

        <RoomActions room={room} />
      </IonList>

      <IonModal isOpen={isOpen} className="searchable-language-modal">
        <IonToolbar>
          <IonTitle>Do you want to pay?</IonTitle>
        </IonToolbar>
        <div className="modal-body">
          <IonText>Room Price</IonText>
          <div className="modal-room-price">
            <IonImg src={sharpStar} />
            <IonText>{roomPrice}</IonText>
          </div>
        </div>
        <IonButtons className="home-filter confirm-buttons-group">
          <IonButton className="confirm-button" onClick={onYesClick}>
            Yes
          </IonButton>
          <IonButton className="confirm-button" onClick={onNoClick}>
            No
          </IonButton>
        </IonButtons>
      </IonModal>
    </>
  );
};

export default LiveRoom;
